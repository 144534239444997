import React from 'react'
import CardList from '../components/CardList'
import DogCard from '../components/DogCard'
import Container from '../components/Container'
import styled from "styled-components";
import Layout from '../components/DefaultLayout';
import { graphql } from "gatsby"


const Hero = styled.img`
  width: 50vw;
  margin: auto;
`

const Dogs = ({data}) => {
    const dogs = data.allContentfulDog.edges;

    return (
        <Layout>
            <Container>
                <Hero src="https://images.ctfassets.net/v9p9nndrs2kj/v1NzupIR1YWWsKUkKUqyC/3d854faea6474b716f5ccc8bf4d295b2/blackinfinity.svg" alt=""/>
                <CardList>
                    {dogs.map(({node: dog}) => (
                        <DogCard
                            key={dog.id}
                            slug={dog.slug}
                            image={dog.image}
                            title={dog.name}
                            date={dog.dateOfBirth}
                        />
                    ))}
                </CardList>
            </Container>
        </Layout>
    )
}

export const query = graphql`
    query dogsQuery {
        allContentfulDog(
            limit: 1000
            sort: { fields: [dateOfBirth], order: DESC }
        ) {
            edges {
                node {
                    name
                    id
                    slug
                    dateOfBirth(formatString: "MMMM DD, YYYY")
                    image {
                        title
                        sizes(maxWidth: 800) {
                            ...GatsbyContentfulSizes_withWebp_noBase64
                        }
                    }
                    titles
                    familyTreeUrl
                }
            }
        }

    }
`

export default Dogs
